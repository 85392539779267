$primary:       #008000;

// .container-fluid{
//     height:100%;
// }
// dropdown
.dropdown-toggle {
    &:after {
        display: none;        
    }
}
.icon-font-size-1{
    font-size: 22px;

}
.btn-circle{
    width: 40px;
    height: 40px;
    padding: 0px 0px;
    border-radius: 20px;
    font-size: 25px;
    text-align: center;
}
// .active
// {background-color: aqua;}


body[data-sidebar=dark] .navbar-brand-box {
    background: #ffffff;
}
.navbar-header {
    height: 60px;
}
.navbar-brand-box {
    padding: 0px 10px;
}
.vertical-menu {
    top: 60px;
}